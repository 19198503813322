import React, { Component } from 'react';
import GetLuxy from '../widgets/get-luxy';
import './about-us.css';
import aboutBg from '../../assets/images/about-bg.png';
import { Accordion, Card, Row } from 'react-bootstrap';
import aboutCar from '../../assets/images/about-cars.png';
import about1 from '../../assets/images/about-1.png';
import about2 from '../../assets/images/about-2.png';
import aboutSteering from '../../assets/images/about-steering.png';

interface AboutProps {

}

interface AboutState {

}

function ContextAwareToggle({ children, eventKey, callback }: any) {
    // const currentEventKey = useContext(AccordionContext);

    // const decoratedOnClick = useAccordionToggle(
    //     eventKey,
    //     () => callback && callback(eventKey),
    // );

    // const isCurrentEventKey = currentEventKey === eventKey;
    {

        return (
            <span><b>
                {/* {
                    (isCurrentEventKey) ? <span className="plus-icon"> -  </span> : <span className="plus-icon"> + </span>
                } */}
                {children}
            </b></span>
        );
    }
}

class About extends Component<AboutProps, AboutState> {
    constructor(props: AboutProps) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <div>
                <div className='about-top'>


                    <div className='get-luxy-widget'>
                        <GetLuxy />

                        <Row>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 about-heading'>
                                <h1 className='text-center'>
                                    LUXY Ride - About Us
                                </h1>
                            </div>
                        </Row>
                        <img style={{ width: '100%', height: '135px' }} src={aboutBg} alt='about' />
                    </div>
                </div>

                <div className='container mx-auto about-content'>
                    <Row>

                        <p className='about-p1'>
                            LUXY Ride aims to make your journey better. We provide a simple booking experience comparable to common ride-hailing apps, except it raises the bar. Unlike the major ride-hailing apps, which rely on part-time drivers and personal cars, every LUXY ride is chauffeured by a licensed professional in a new, safety-inspected luxury sedan or SUV, ensuring a reliable, comfortable, and safe journey to and from airports across the country. Travelers can get quotes and book rides on the LUXY website, the free LUXY Ride mobile app for iPhone/iPad and Android, or by calling one of LUXY's live customer service representatives available 24 hours a day, seven days a week.
                        </p>

                    </Row>

                    <hr className="my-5" />
                    <Row>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 pt-2'>
                            <Accordion activeKey="0">
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="0">What is LUXY Ride?</ContextAwareToggle>
                                        </h6>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='font-14 pt-0'>
                                            <ul>
                                                <li>
                                                    We have access to the fastest-growing network of black car services, and we've used our technology to help our partners improve their driving experience while lowering carbon emissions.
                                                </li>
                                                <li>
                                                    We were able to build a travel experience unlike any other - from booking to destination - with more than 100 years of combined chauffeur experience, a 24/7 dedicated Client Care Team, and a little magic.
                                                </li>
                                                <li>
                                                    LUXY is reviving the luxury car transportation industry with its proprietary technology, taking local and regional specialist car service providers into the 21st century, and upgrading their services to compete with major ride-hailing companies.
                                                </li>
                                                <li>
                                                    LUXY has combined a revolutionary booking platform to link travelers with untapped premium car inventory, offering a better service experience for less money.
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 pt-5  text-center'>
                            <img src={aboutCar} className="img-fluid" alt='About Car' />
                        </div>
                    </Row>
                    <hr className="my-5" />
                    <Row>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 pt-5 text-center'>
                            <img src={about2} alt='About Car' className="img-fluid" />
                        </div>
                        <div className='col-12 col-sm-12 col-md-6  pt-2 col-lg-6'>
                        <Accordion activeKey="1">
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" >
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="1">Our Chauffeur Services</ContextAwareToggle>
                                        </h6>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='font-14 pt-0'>
                                        <ul>
                                                <li>Our dynamic community has a passion for using technology and providing a positive traveler experience. These passions help make luxury transportation chauffeur services more affordable and available across the country for you.</li>
                                                <li>You can book a ride when arriving at JFK and then one for traveling back home at LAX. We have got you covered from coast to coast!</li>
                                                <li>Easy app-based tracking and booking features, similar to famous ride-hailing apps.</li>
                                                <li>Plan ahead with LUXY’s all inclusive pricing, which ensures optimal rates at the moment you book.</li>
                                                <li>We provide an innovative booking platform to connect travelers with untapped premium car inventory and offer a better service experience for a lower cost.</li>
                                                <li>24/7 support by our live Client Care team.</li>
                                                <li>And much more! Contact us today to book your ride.</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                        </div>
                    </Row>
                    <hr className="my-5" />
                    <Row>
                        <div className='col-12 col-sm-12 col-md-6  pt-2 col-lg-6'>
                        <Accordion activeKey="2">
                                <div>
                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                        <h6 className="pointer">
                                            <ContextAwareToggle eventKey="2">Why Luxy Ride?</ContextAwareToggle>
                                        </h6>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className='font-14 pt-0'>
                                        <ul>
                                                <li>Budget-friendly - You don't have to break the bank to have an unforgettable vacation. There are no hidden costs, and you will always get the best price.</li>
                                                <li>Trusted network - It can be challenging to find a dependable limo service, but we made it simple by working with only the best of the best around the United States.</li>
                                                <li>Vehicles - Upgrade your mode of transportation! You're a VIP, and you deserve to be chauffeured in a luxury sedan.</li>
                                                <li>Check our tech - Simply book, receive ride alerts, save your payment information, and remember to have fun!</li>
                                                <li>Availability - Do you need a ride right now, or are you planning your next family vacation? We're here for you 24 hours a day, seven days a week, 365 days a year.</li>
                                                <li>Care team - We provide you with world-class customer support in response to your order. Get a free quote today by speaking to a live agent.
                                                </li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 pt-5 text-center'>
                            <img src={about1} alt='About Car' className="img-fluid" />
                        </div>
                    </Row>
                   </div>

                <hr className="my-5" />
                <div className="about-car-inner-test">
                    <Row>
                        <div className="col-12 col-sm-12 col-ms-12 col-lg-12 testimonial-block text-center">
                            <h4 className="text-center">Are you still unsure? Have a look at some testimonials from our previous customers.</h4>
                            <div>
                                <div className="testimonial-item">
                                    <p> <i>
                                        “This was my first experience riding with LUXY. They were professional, on time, and friendly drivers. This will be my go-to for all my many flights across the country.” - Suzanne</i></p>
                                </div>
                                <div className="testimonial-item">
                                    <p> <i>“Our driver was prompt, courteous, and professional. I arrived with a friend, two dogs, and six suitcases (moving), and he took it all in stride, smiling.” - Nina
                                    </i></p>
                                </div>

                                <div className="testimonial-item">
                                    <p> <i>“I needed a car service at 6 am to get to an airport 2 hours out of a town in the middle of rural PA. My driver came 15 minutes early (a good thing) and got me to the airport with time to spare. I would use LUXY again for sure. They are reliable and professional.” - Elle
                                    </i></p>
                                </div>

                                <div className="testimonial-item">
                                    <p> <i>“My flight arrived a little earlier than scheduled, and the driver was aware of it. He was already there when my plane landed. He also made sure my host was home before driving away. Thank you!” - Gabriela
                                    </i></p></div>
                            </div>
                        </div>
                    </Row>
                </div>
                {/* <hr className="my-5" />
                <div className="about-car-inner-test">
                    <Row className=''>
                        <div className="col-12 col-sm-12 col-ms-12 col-lg-12 testimonial-block text-center">
                            <h4> More about LUXY Ride - Chauffeur Services</h4>
                            <p className="text-justify">LUXY is the smart new way to get to and from airports around the country. Via LUXY's expanding network of experienced drivers and services, travelers can expect a secure, premium experience. Passengers can rely on LUXY for a safe, reliable ride to the airport in style and comfort and take advantage of our daily special low prices. Our cutting-edge technology and live customer service team monitor every LUXY ride, ensuring a stress-free trip every time. Customers can book all of their rides using LUXY's simple app, website at LUXYride.com, or by calling 833.GET.LUXY. Visit luxyride.com for more details, and find us on Facebook, Twitter, and Instagram.</p>
                        </div>
                    </Row>
                </div> */}
                <div className="about-car-inner">
                    <img className='about-car-inner img-fluid' src={aboutSteering} alt='Car inner' />
                    <Row className=''>
                        <div className="col-12 col-sm-12 col-ms-12 col-lg-12 text-block text-center">
                            <h4>More about LUXY Ride - Chauffeur Services</h4>
                            <p>
                                LUXY is the smart new way to get to and from airports around the country. Via LUXY's expanding network of experienced drivers and services, travelers can expect a secure, premium experience. Passengers can rely on LUXY for a safe, reliable ride to the airport in style and comfort and take advantage of our daily special low prices. Our cutting-edge technology and live customer service team monitor every LUXY ride, ensuring a stress-free trip every time. Customers can book all of their rides using LUXY's simple app, website at LUXYride.com, or by calling(833) 438-5899 Visit luxyride.com for more details, and find us on Facebook, Twitter, and Instagram.
                            </p>

                        </div>
                    </Row>
                </div>
            </div>

        )
    }
}

export default About;