import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { toast, ToastContainer } from 'react-toastify';
import { UserService } from '../../services/user';
import AddressBlock from './address-block';
import Trips from './trips';
import { FormControl, Modal } from 'react-bootstrap';
import * as Helpers from '../common/helpers';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import DeleteConfirmPopup from '../modals/delete-confirmation';
import { createEventGA4 } from '../../Utilities/helpers';
import DeleteAccountModal from './delete-account-modal';
const ProfileImg = require('../../assets/images/user-profile.png');

interface ProfileProps {
    getUserInfo: (userId: any) => any;
    getPastTripData: (userId: any) => any;
    getUpcomingTripData: (userId: any) => any;
    getInprogressTripData: (userId: any) => any;
    getAddons: () => any;
    userInfo: any;
    isUserLogin: boolean;
    pastTripData: any;
    upcomingTripData: any;
    inprogressTripData: any;
    addonsList: any;
    updateUserInfo: () => any;
    rideRewardsConfigData: any;
    updateUserDeleteAccount: () => any;
}
interface ProfileState {
    userData: any;
    file: any;
    selectedFileName: any;
    showProfileModal: boolean;
    firstNameErr: boolean;
    lastNameErr: boolean;
    emailErr: boolean;
    mobileErr: boolean;
    showDeleteConfirmPopup: boolean;
    showDeleteAccountModal: boolean;
    emailToVerify: any;
    emailToVerifyErr: any;
    notifyUserErrMsg: any;
    showOtpVerification: boolean;
    disableEmailToVerify: boolean;
    verifyOtpMsgSuccess: any;
    showDeleteButton: boolean;
    confirmDeleteMsg: any;
    otpErrMsg: any;
    clearOtp: boolean;
}

class Profile extends React.Component<ProfileProps, ProfileState>{
    hiddenFileInput: any
    constructor(props: ProfileProps) {
        super(props);
        this.hiddenFileInput = React.createRef();
        this.state = {
            userData: {},
            file: null,
            selectedFileName: '',
            showProfileModal: false,
            firstNameErr: false,
            lastNameErr: false,
            emailErr: false,
            mobileErr: false,
            showDeleteConfirmPopup: false,
            showDeleteAccountModal: false,
            showOtpVerification: false,
            emailToVerify: '',
            emailToVerifyErr: '',
            notifyUserErrMsg: '',
            disableEmailToVerify: false,
            verifyOtpMsgSuccess: '',
            showDeleteButton: false,
            confirmDeleteMsg: '',
            otpErrMsg: '',
            clearOtp: false,
        }
    }

    async componentDidMount() {
        let isUserLogin = sessionStorage.getItem('isLoggedIn');
        if (isUserLogin == 'true') {
            let user = sessionStorage.getItem('user');
            if (user != null && user != undefined) {
                let userObj = JSON.parse(user);
                this.setState({ userData: userObj });
                await this.props.getUserInfo(userObj['id']);
                await this.props.getPastTripData(userObj['id']);
                await this.props.getUpcomingTripData(userObj['id']);
                await this.props.getInprogressTripData(userObj['id']);
                await this.props.getAddons()
                
            }
        }
    }

    componentWillUnmount() {
        this.props.updateUserInfo();
    }

    handleChange = async () => {

    }

    getAwsPresignedUrl = async (payload: any) => {

    }

    fileToDataUri = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
            resolve(event.target.result)
        };
        reader.readAsDataURL(file);
    })

    getBlob = async (fileUri: string) => {
        const resp = await fetch(fileUri);
        const imageBody = await resp.blob();
        return imageBody;
    };


    checkFileType($event: any) {
        let file = $event.target.files[0];
        let selectedFileName = $event.target.files[0].name;
        if (!this.validateFile(selectedFileName)) {
            return;
        }

        this.setState({
            selectedFileName: selectedFileName,
            file: file
        }, async () => {
            let userId = this.state.userData.id;
            let key = userId + '.png';
            let preSignedUrlData = await UserService.getAwsPresignedUrl({ key: key, user_id: userId });
            if (preSignedUrlData && preSignedUrlData.data && preSignedUrlData.data.data) {
                const url = preSignedUrlData.data.data;

                const xhr = new XMLHttpRequest();
                xhr.open('PUT', url);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            this.props.getUserInfo(userId);
                            toast.success(`Profile picture updated successfully.`, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        } else {
                            toast.error(`Oops Something went wrong. Please try after some time.`, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }
                    }
                };
                xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                xhr.send(file);
            }
            // console.log(preSignedUrlData, 'preSignedUrlData');
        })
    }

    validateFile(fileName: string) {
        const allowedFiles = ['.png', '.jpg', '.jpeg', '.gif'];

        // const regex = new RegExp('([a-zA-Z0-9\s_\\.\-:])+(' + allowedFiles.join('|') + ')$');
        const regex = new RegExp('.*\.(' + allowedFiles.join('|') + ')');
        if (!regex.test(fileName.toLowerCase())) {
            // this.fileErrorMessage = 'Please select pdf docx doc mp4 xls xlsx csv png jpg jpeg gif formats only';
            toast.error(`Please select png jpg jpeg only.`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        } else {
            // this.fileErrorMessage = '';
            return true;
        }

    }

    triggerFileChange = () => {
        this.hiddenFileInput.current.click();
    }

    submitTripRating = async (rating: any, tripId: any) => {
        await UserService.submitRideRating(rating, this.state.userData.id, tripId);
        toast.success(`rating submitted successfully.${rating}, ${this.state.userData.id}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        await this.props.getPastTripData(this.state.userData.id);
    }

    handlePdfDownload = async (data: any) => {
        let responseData: any = await UserService.renderPdf(data);
        //  let downloadData: any = await UserService.downloadPdf(data.id);
        //  console.log(downloadData, 'responseData')
        // const pdfBlob = new Blob([responseData.data], { type: 'application/pdf' });
        // saveAs(pdfBlob, `trip_${data.id}.pdf`);
        const url = responseData.data;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `trip_${data.id}.pdf`);
        document.body.appendChild(link);
        link.click();
        this.createDownloadPDFEvent(data.id);

        // const downloadUrl = window.URL.createObjectURL(new Blob([downloadData.data]));

        // const link = document.createElement('a');

        // link.href = downloadUrl;

        // link.setAttribute('download', 'test.pdf'); //any other extension

        // document.body.appendChild(link);

        // link.click();

        // link.remove();

    }

    createDownloadPDFEvent = (tripID: any) => {
        const obj: any = {
            'eventName': 'download_invoice',
            'tripId': tripID.toString(),
            'event': 'downloadInvoice'
        }
        createEventGA4(obj);
    }

    showProfileModal = () => {
        this.setState({
            showProfileModal: true
        })
    }

    closeProfileModal = () => {
        this.setState({
            showProfileModal: false
        })
    }

    handleFieldChange = (e: any, type: string = '') => {
        // console.log("event", e)
        let key = '';
        let value = '';
        if (type === 'mobile_number') {
            key = 'mobile_number';
            value = e;
        } else {
            key = e.target.name;
            value = e.target.value;
        }
        let stateCopy: any = Object.assign({}, this.state.userData);

        stateCopy[key] = value;
        // console.log(stateCopy, key, value);
        this.setState({ userData: stateCopy });
    }

    validate = async () => {
        (this.state.userData.first_name.trim() === '') ? this.setState({ firstNameErr: true }) : this.setState({ firstNameErr: false });
        (this.state.userData.last_name.trim() === '') ? this.setState({ lastNameErr: true }) : this.setState({ lastNameErr: false });
        (this.state.userData.email_address === '' || !Helpers.validateEmail(this.state.userData.email_address)) ? this.setState({ emailErr: true }) : this.setState({ emailErr: false });
        (this.state.userData.mobile_number === '' || !Helpers.validateCountryPhone(this.state.userData.mobile_number)) ? this.setState({ mobileErr: true }) : this.setState({ mobileErr: false });
    }

    updateUser = async () => {
        // console.log(this.state.userData, this.props.userInfo, 'test123');
        await this.validate();
        if (!this.state.firstNameErr && !this.state.lastNameErr && !this.state.emailErr && !this.state.mobileErr) {

            let userData = this.state.userData;
            let payload = {
                first_name: userData ? userData?.first_name : '',
                last_name: userData ? userData?.last_name : '',
                email_address: userData ? userData?.email_address : '',
                mobile_number: userData ? userData?.mobile_number : '',
                home_address: this.props.userInfo.home_address,
                work_address: this.props.userInfo.work_address,
                state_id: 0,
                zipcode_id: 0,
                city_id: 0,
                work_state_id: 0,
                work_city_id: 0,
                work_zipcode_id: 0,
                work_city_name: '',
                home_city_name: '',
                airport_id: this.props.userInfo.airport_id,
                is_active: true,
                is_email_notification_allowed: true,
                is_sms_notification_allowed: true,
                unique_id: userData ? userData.unique_id : null
            }

            let updatedData = await UserService.updateAddressInfo(payload, userData.id);
            if (updatedData.data && updatedData.data.status == 200) {
                toast.success(`Profile details updated successfully.`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                this.setState({ showProfileModal: false }, async () => {
                    await this.props.getUserInfo(userData.id);

                    const data = {
                        ...this.props.userInfo,
                        id: userData.id
                    }
                    this.setState({userData: data}, () => {
                        sessionStorage.removeItem('user');
                        sessionStorage.setItem('user', JSON.stringify(data));
                    });


                });
            } else {
                toast.error(`OOps something went wrong!.`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    notifyUser = async () => {
        try {
            if (!this.state.emailToVerify.trim()) {
                this.setState({ emailToVerifyErr: 'Email is required' });
            }  else if (this.state.emailToVerify.toLowerCase() !== this.state.userData?.email_address.toLowerCase()) {
                this.setState({ emailToVerifyErr: 'Email does not match the current login account. Please enter the logged in email account.' });
            } else {
                const { id: userId, mobile_number: mobileNumber } = this.state.userData;
                const userInfo = {
                    "user_id": userId,
                    "mobile_number": mobileNumber,
                    "email_address": this.state.emailToVerify
                }
                this.setState({ clearOtp: true });    
                this.setState({ otpErrMsg: '' });
                const response: any = await UserService.deactivateUser(userInfo);
                if (response) {
                    if (response.error) {
                        this.setState({ notifyUserErrMsg: response.error.data.message });
                    } else if (response && response.data && response.data.status === 200) {
                        toast.success(response.data.message ? response.data.message : '');
                        this.setState({ disableEmailToVerify : true  });
                        this.setState({ showOtpVerification: true });
                    }
                }
                this.setState({ clearOtp: false });
            }
        } catch (error) {
            this.setState({ clearOtp: false });
        }
    }

    verifyOtp = async (otp: string) => {
        try {
            if (otp.length !== 5) {
                this.setState({ otpErrMsg: 'Invalid Code' });
            } else {
                this.setState({ otpErrMsg: '' });
                const { id: userId, mobile_number: mobileNumber, email_address: emailAddress } = this.state.userData;
                const response = await UserService.verifyOtp(emailAddress, userId, otp);
                if (response) {
                    if (response.error) {
                        toast.error(response.error?.data?.message);
                    } else if (response && response.data && response.data.status === 200) {
                        this.setState({ verifyOtpMsgSuccess:  response.data.message ? response.data.message : '' });
                        this.setState({ showDeleteButton: true });
                        this.setState({ showOtpVerification: false });
                    }
                }
            }
        } catch (error) {
            
        }
    }

    confirmDelete = async () => {
        try {     
            const { id: userId, email_address: emailAddress } = this.state.userData;
            const response = await UserService.confirmDelete(emailAddress, userId);
            
            if (response && response.data && response.data.status === 200) {
                toast.success(response.data.message);
                setTimeout(() => {
                    this.props.updateUserDeleteAccount();
                }, 1500);
            }
        } catch (error) {
            
        }
    }

    showDeleteConfimation = () => {
        this.setState({ showDeleteConfirmPopup: true });
    }

    closeDeleteConfimation = () => {
        this.setState({ showDeleteConfirmPopup: false });
    }

    closeDeleteAccountModal = () => {
        this.setState({ showDeleteAccountModal: false });
        this.setState({ disableEmailToVerify : false  });
        this.setState({ emailToVerify: '' });
        this.setState({ notifyUserErrMsg: '' });
        this.setState({ showOtpVerification: false });
        this.setState({ showDeleteButton: false });
    }

    showDeleteAccountModal = () => {
        this.setState({ showDeleteConfirmPopup: false });
        this.setState({ showDeleteAccountModal: true });
    }

    setEmailToVerify = (value: string) => {
        this.setState({ emailToVerify: value });
    }

    setEmailToVerifyErr = () => {
        this.setState({ emailToVerifyErr: '' }); 
        this.setState({ notifyUserErrMsg: '' });     
    }

    renderProfileModal = () => {
        return <Modal show={true} style={{ top: '0px' }} scrollable={true} >
            <div className="float-right">
                <i className="fa fa-times float-right pointer"
                    onClick={() => this.closeProfileModal()}
                    title="close"></i>
            </div>
            <Modal.Header>
                UPDATE USER PROFILE
            </Modal.Header>
            <Modal.Body style={{ overflowY: 'scroll' }}>
                <div>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">First Name <span className="importantfields">*</span></label>
                                <FormControl
                                    type="first_name"
                                    id="first_name"
                                    name="first_name"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.userData.first_name}

                                />
                                {(this.state.firstNameErr) ? <span className="error">First name required.</span> : null}

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Last Name <span className="importantfields">*</span></label>
                                <FormControl
                                    type="last_name"
                                    id="last_name"
                                    name="last_name"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.userData.last_name}

                                />
                                {(this.state.lastNameErr) ? <span className="error">Last name required.</span> : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Email Address <span className="importantfields">*</span></label>
                                <FormControl
                                    type="email_address"
                                    id="email_address"
                                    name="email_address"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.userData.email_address}

                                />
                                {(this.state.emailErr) ? <span className="error">Invalid Email.</span> : null}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Mobile Number <span className="importantfields">*</span></label>
                                {/* <FormControl
                                    type="mobile_number"
                                    id="mobile_number"
                                    name="mobile_number"
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.userData.mobile_number}

                                /> */}

                                <div className="form-group input-group" style={{ display: 'grid' }}>


                                    <PhoneInput
                                        defaultCountry='US'
                                        international
                                        name="mobile_number"
                                        onChange={(event: any) => this.handleFieldChange(event, "mobile_number")}
                                        value={this.state.userData.mobile_number}
                                    />



                                </div>
                                {/* <PhoneInput
                                            defaultCountry='US'
                                            international
                                            placeholder={
                                                "Enter Contact Number"
                                            }
                                            onChange={(event: any) => { this.handleFieldChange(event) }}
                                            value={this.state.userData.mobile_number}
                                        /> */}
                                {(this.state.mobileErr) ? <span className="error">Invalid mobile number.</span> : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="address_type" className="float-left">Reference Number</label>
                                <FormControl
                                    type="unique_id"
                                    id="unique_id"
                                    name="unique_id"
                                    maxLength={6}
                                    onChange={(event: any) => { this.handleFieldChange(event) }}
                                    value={this.state.userData.unique_id}

                                />
                                {(this.state.lastNameErr) ? <span className="error">Last name required.</span> : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="row no-gutters">
                                <div className="col">
                                    <button
                                        onClick={() => { this.updateUser() }} className="btn btn-block primary custom-button">UPDATE</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    }

    render() {
        let userData = this.props.userInfo;
        let userImage = (userData.profile_image && userData.profile_image != null && userData.profile_image != '') ? userData.profile_image : ProfileImg
        return <div>
            {/* <GetLuxy /> */}
            <ToastContainer />
            <div className='about-top'>
                <div className='get-luxy-widget'>
                    <h1 className='text-center'>MY PROFILE</h1>
                </div>
            </div>

            <div className='about-content'>
                <div className="">  <div className="container-fluid container-sm" >
                    <Row className="profile-user" noGutters={true}>
                        <div className="card p-0 w-100">
                            <div className="card-body p-md-3 py-1 p-0">
                                <Row className="mb-4">
                                    <div className="col-auto">
                                        <div className="media">
                                            <div className="media-left" >
                                                <div className="photo">
                                                    <img id="image"
                                                        onClick={() => { this.triggerFileChange() }}
                                                        className="pointer"
                                                        src={userImage} style={{ borderRadius: "50%", height: '100px', width: '100px' }} 
                                                        alt="avatar"
                                                        />
                                                    <div className="img-edit">
                                                        <i className="fa fa-pencil pointer" onClick={() => { this.triggerFileChange() }} aria-hidden="true"></i>
                                                    </div>
                                                    <input type="file" id="myfile" ref={this.hiddenFileInput}
                                                        onChange={(event) => { this.checkFileType(event); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="profile-name">{userData.first_name + ' ' + userData.last_name}</div>
                                        <div className="profile-number">
                                            <div className="phone-icon"><i className="fa fa-phone icon-yellow" aria-hidden="true"></i></div>
                                            <div className="phone-text">{userData.mobile_number}</div>
                                        </div>
                                        <div className="profile-mail">
                                            <div className="mail-icon"><i className="fa fa-envelope icon-yellow" aria-hidden="true"></i></div>
                                            <div className="mail-text">{userData.email_address}</div>
                                        </div>
                                        {
                                            (this.props.rideRewardsConfigData && 
                                                this.props.rideRewardsConfigData.value === 'true'
                                                &&
                                                userData && userData.is_enrolled_into_riderewards)
                                                ?
                                                <div className="profile-mail">
                                                    <div className="mail-icon"><i className="fas fa-award icon-yellow"
                                                    title="Reward Points" aria-hidden="true"></i></div>
                                                    <div className="mail-text">{(userData.points) ? userData.points : 0}</div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="col">
                                        <div className='float-right'>
                                            <div>
                                                <i className="fa fa-pencil-square-o pointer" onClick={() => { this.showProfileModal() }} aria-hidden="true"></i>
                                            </div>
                                            <div>
                                                <i className="fa fa-trash pointer" onClick={() => { this.showDeleteConfimation() }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div className="address-block p-2">
                                    <AddressBlock
                                        userData={userData}
                                        getUserInfo={this.props.getUserInfo}
                                    />
                                </div>
                            </div>
                        </div>
                    </Row></div></div>

                <div className="container mt-4">
                    <div className="card p-0 w-100">
                        <div className="card-body p-md-3 py-1 p-0">
                            <Trips
                                pastTripData={this.props.pastTripData}
                                upcomingTripData={this.props.upcomingTripData}
                                inprogressTripData={this.props.inprogressTripData}
                                addonsList={this.props.addonsList}
                                handleTripRating={this.submitTripRating}
                                handlePdfDownload={this.handlePdfDownload}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {
                (this.state.showProfileModal) ?
                    this.renderProfileModal() : null
            }
            {
                <DeleteConfirmPopup
                    show={this.state.showDeleteConfirmPopup}
                    closeConfirmPopup={this.closeDeleteConfimation}
                    title={'Delete Account'}
                    bodyText={'Are you sure you would like to delete your account?'}
                    deleteMethod={this.showDeleteAccountModal}
                />
            }
            {   
                <div>
                    <DeleteAccountModal
                        notifyUser={this.notifyUser}
                        verifyOtp={this.verifyOtp}
                        closeModal={this.closeDeleteAccountModal}
                        setEmailToVerify={this.setEmailToVerify}
                        showDeleteAccountModal={this.state.showDeleteAccountModal}
                        email = { this.state.emailToVerify }
                        emailErr = { this.state.emailToVerifyErr }
                        userData = {this.state.userData}
                        notifyUserErrMsg = { this.state.notifyUserErrMsg }
                        showOtpVerification = { this.state.showOtpVerification }
                        disableEmailToVerify = { this.state.disableEmailToVerify}
                        verifyOtpMsgSuccess={ this.state.verifyOtpMsgSuccess }
                        showDeleteButton={ this.state.showDeleteButton }
                        confirmDelete = { this.confirmDelete }
                        confirmDeleteMsg = { this.state.confirmDeleteMsg }
                        setEmailErr = { this.setEmailToVerifyErr }
                        otpErrMsg = { this.state.otpErrMsg }
                        clearOtp = { this.state.clearOtp }
                    />
                </div>
            }
        </div>
    }
}
export default Profile;